$black: #000000;
$blueGray: #9ea7b3;
$borderGray: #e1e1e1;
$darkestGray: #202020;
$darkGray: #3d3d3d;
$grapefruit: #e76366;
$lightGray: #ececec;
$lightGreen: #c7efe6;
$mediumGrayOne: #6c6c6c;
$mediumGrayTwo: #c1c1c1;
$mediumGrayThree: #818080;
$polloGreen: #29c09e;
$white: #ffffff;
$whiteTwo: #fafafa;
